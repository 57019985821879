import React, { useState, useEffect } from "react";
import axios from "./custom-axios/axios";
import "./DropdownPollutant.css";
import "bootstrap/dist/css/bootstrap.min.css";

function DropdownArea(props) {
  const [selectedValue, setSelectedValue] = useState("");
  const [options, setOptions] = useState([]);

  const handleChange = (event) => {
    let area = options.find((o) => o.id === parseInt(event.target.value));
    setSelectedValue(area.id);
    props.changeDomain(area);
  };

  useEffect(() => {
    fetchAreas();
  }, []);

  async function fetchAreas() {
    axios
      .get("/simulation-areas")
      .then(({ data }) => {
        setOptions(data);
      })
      .catch(() => {
        setOptions(["Options failed to load."]);
      });
  }
  return (
    <div className="selectPollutantSwitch selectDomain">
      <label className="selectPollutantLabel" htmlFor="pollutant-select">
        Избери регион:{" "}
      </label>
      <select
        className="selectPollutant"
        id="pollutant-select"
        value={selectedValue}
        onChange={handleChange}
      >
        {options ? (options.map((area) => (
          <option
            className="selectPollutantOption"
            key={area.id}
            value={area.id}
          >
            {area.areaName}
          </option>
        ))) : <option></option>}
      </select>
    </div>
  );
}

export default DropdownArea;
