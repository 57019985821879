import React, { useState, useEffect } from "react";
import axios from "./custom-axios/axios";
import "./DropdownPollutant.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Spinner } from "react-bootstrap";
import "./SelectedLocation.css";
import LineChart from "./DetailsForPollutantsLineChart";

function DetailsForPollutantsByLocation(props) {
  const [pollutants, setPollutants] = useState(new Map());
  const [loadingData, setLoadingData] = useState(true);
  const [days, setDays] = useState([]);
  const [hours, setHours] = useState([
    ...Array(24).keys(),
    ...Array(24).keys(),
    ...Array(24).keys(),
  ]);
  const [daysMinus, setDaysMinus] = useState(0);
  const [daysPlus, setDaysPlus] = useState(4);

  useEffect(() => {
    fetchPollutants();
  }, [props.location]);

  const fetchPollutants = async () => {
    setLoadingData(true);
    const res = await axios
      .get(
        "/spot/daysplus/" +
          daysPlus +
          "/daysminus/" +
          daysMinus +
          "/lat/" +
          props.location.latitude.toFixed(5) +
          "/lng/" +
          props.location.longitude.toFixed(4) +
          "/"
      )
      .catch(() => {
        props.openErrDivDetails();
      });
    if (res === undefined || res.data.length === 0) {
      props.openErrDivDetails();
      return;
    }
    setLoadingData(false);
    setPollutants(res.data);
    createDays();
  };

  function createDays() {
    let daysP = daysPlus;
    let daysM = daysMinus;
    let weekday = [
      "Недела",
      "Понеделник",
      "Вторник",
      "Среда",
      "Четврток",
      "Петок",
      "Сабота",
    ];
    let realDays = [];

    let newDate = new Date();
    let date = new Date();
    for (let i = 0 - daysM; i < daysP; i++) {
      newDate.setDate(date.getDate() + i);
      realDays.push(
        weekday[newDate.getDay()] +
          " " +
          newDate.getDate() +
          "." +
          (newDate.getMonth() + 1) +
          "." +
          newDate.getFullYear()
      );
    }
    setDays(realDays);
  }

  function renderHours() {
    let res = [];
    let thisHours = hours;
    for (let i = 0; i < hours.length; i++) {
      if (thisHours[i] === 23) {
        res.push(
          <td className="hours-end" key={i}>
            {thisHours[i]}ч
          </td>
        );
      } else
        res.push(
          <td className="hours" key={i}>
            {thisHours[i]}ч
          </td>
        );
    }
    return res;
  }

  function renderItems(key, paramValues) {
    let values = [];

    for (let i = 0; i < paramValues.length; i++) {
      let val = paramValues[i].val;
      if (val === null && val === undefined) {
        continue;
      }
      if (val === "NaN") {
        values.push(
          <td className="pollutant-td" key={i} style={{ background: "white" }}>
            NoVal
          </td>
        );
        continue;
      }
      let color = getBackgroundColor(key, val);
      values.push(
        <td className="pollutant-td" key={i} style={{ background: color }}>
          {val.toFixed(1)}
        </td>
      );
    }
    return values;
  }

  const scrollValues = () => {
    window.onload += function () {
      document.getElementById("scroll-table").scrollLeft = 40;
      console.log(document.getElementById("scroll-table").scrollLeft);
    };
  };

  function getBackgroundColor(pollutant, value) {
    if (pollutant === "alert") {
      if (value < 1) {
        return "linear-gradient(180deg, white 0%, lightgray 150%)";
      } else if (value < 2) {
        return "linear-gradient(180deg, white 0%, green 150%)";
      } else if (value < 3) {
        return "linear-gradient(180deg, white 0%, orange 150%)";
      } else if (value < 4) {
        return "linear-gradient(180deg, white 0%, red 150%)";
      } else if (value < 5) {
        return "linear-gradient(180deg, white 0%, violet 150%)";
      } else {
        return "linear-gradient(180deg, white 0%, gray 150%)";
      }
    }
    if (pollutant === "rain") {
      if (value < 1) {
        return "violet";
      } else if (value < 2.5) {
        return "blue";
      } else if (value < 5) {
        return "cyan";
      } else if (value < 7.5) {
        return "turquoise";
      } else if (value < 10) {
        return "green";
      } else if (value < 12.5) {
        return "chartreuse";
      } else if (value < 15) {
        return "yellow";
      } else if (value < 17.5) {
        return "brown";
      } else if (value < 20) {
        return "red";
      } else if (value >= 20) {
        return "orange";
      }
    }
  }

  return (
    <div className="detailContainer">
      {loadingData && (
        <div className="spinner">
          <Spinner animation="grow" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
          <Spinner animation="grow" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
          <Spinner animation="grow" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )}
      {!loadingData && (
        <div style={{ height: 100 + "%" }}>
          <div
            onScroll={scrollValues()}
            className="div-table"
            id="scroll-table"
            style={{ overflowY: "scroll" }}
          >
            <table style={{ width: 1000 }}>
              <tbody>
                <tr style={{ marginLeft: "1em" }}>
                  {days.map((day, index) => (
                    <td
                      style={{
                        borderRight: "1px solid gray",
                        height: "30px",
                        fontSize: "14px",
                      }}
                      colSpan={index === 0 ? 25 : 24}
                      key={index}
                    >
                      {day}
                    </td>
                  ))}
                </tr>
                {Object.entries(pollutants).map(([key, value]) => (
                  <React.Fragment key={key}>
                    <tr>
                      <td className="parameterName" rowSpan={3}>
                        <p>{key}</p>
                      </td>
                    </tr>
                    <tr>{renderItems(key, value)}</tr>
                    <tr>
                      <td colSpan={daysPlus*24} style={{ height: "18em" }}>
                        <LineChart
                          parameter={key}
                          chartData={value}
                          hours={hours}
                          style={{ marginLeft: "-10px" }}
                        ></LineChart>
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
}

export default DetailsForPollutantsByLocation;
