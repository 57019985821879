import React, { useState, useEffect } from 'react';
import axios from './custom-axios/axios';
import "./DropdownPollutant.css"
import 'bootstrap/dist/css/bootstrap.min.css'


function Dropdown (props) {
  const [selectedValue, setSelectedValue] = useState("");
  const [options, setOptions] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    axios
      .get("/area-parameters")
      .then(({ data }) => {
        setOptions(data);
      })
      .catch(() => {
        setOptions(["Options failed to load."]);
      });
  }

  const handleChange = (event) => {
    let areaParam = options.find(
      (o) =>
        o.id.pollutantId === parseInt(event.target.value) 
    );
    if(areaParam.id) {
      setSelectedValue(areaParam.id.pollutantId);
    }
    props.changePollutant(areaParam);
  }

  return (
    <div className="selectPollutantSwitch">
      <label className="selectPollutantLabel" htmlFor="pollutant-select">
        Избери тип:{" "}
      </label>
      <select
        className="selectPollutant"
        id="pollutant-select"
        value={selectedValue}
        onChange={handleChange}
      >
        {options ? (options.map((areaParam) => (
          <option
            className="selectPollutantOption"
            key={areaParam.id ? areaParam.id.pollutantId : ''}
            value={areaParam.id ? areaParam.id.pollutantId : ''}
          >
            {areaParam.screenName}
          </option>
        ))) : <option></option>}
      </select>
    </div>
  );
}

export default Dropdown;
