import React from 'react'
import L from 'leaflet'
import {Map, Marker, Popup, TileLayer} from 'react-leaflet';
import iconMarker from "../logo/onlyLogo.png"
import iconRetina from "../logo/onlyLogo.png"
import iconShadow from 'leaflet/dist/images/marker-shadow.png'
import {Container} from "react-bootstrap";
import {geolocated} from "react-geolocated";
import ErrDiv from '../errorMsg';
import '../mobile/map-mobile.css';

class MobileMap extends React.Component {

    constructor(props) {
        super(props);
        this.mapRef = React.createRef();
        this.updateMarkers = this.updateMarkers.bind(this);
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
        this.state = {
            bound: L.latLngBounds(L.latLng(37.18222, 11.08), L.latLng(46.97, 26.899)),
            zoom: 8,
            centerMap: [41.612, 21.713],
            minZoom: 6,
            maxZoom: 10,
            positionCenterLat: 42.642,
            positionCenterLng: 18.94,
            sidePanelVisible: false,
            errMSGVisible: false,
            errorText: "",
            markers: [],
            inputForLocationDescription: false,
            currentDescription: "",
            currentLat: 0,
            currentLng: 0,
            testMessage: "",
        }
    }

    componentDidMount() {
        document.addEventListener("message", e => this.updateMarkers(e));
    };

    componentWillUnmount() {
        document.removeEventListener("message", e => this.updateMarkers(e))
    }

    updateMarkers(e) {
        if (e.data.markers._j === null || e.data.markers._j === undefined) {
            return;
        }
        let message = JSON.parse(JSON.stringify(e.data.markers._j));
        var markers = [];
        for (var i = 0; i < message.length; i++) {
            markers.push(message[i]);
        }
        this.setState({
            markers: markers
        });
    }

    sendDataToNativeApp = (data) => {
        var message = {message: "saveData", data: JSON.stringify(data)};
        window.ReactNativeWebView.postMessage(JSON.stringify(message));
    };

    openInputForLocationDescription = (e) => {
        this.setState({
            inputForLocationDescription: true,
        })
        this.addCurrentMarkerLocation(e);
    }

    addCurrentMarkerLocation(e) {
        this.setState({
            currentLat: e.latlng.lat,
            currentLng: e.latlng.lng,
        })
    }

    closeInputForLocationDescription = () => {
        this.setState({
            inputForLocationDescription: false,
            errMSGVisible: false
        })
    }

    handleDescriptionChange(event) {
        console.log(event.target.value);
        this.setState({
            currentDescription: event.target.value
        });
    }

    addMarker = (e) => {
        e.preventDefault();
        let markers = this.state.markers;
        if (markers.length >= 10) {
            this.setState({
                errorText: "Може да додадете само 10 локации.",
                errMSGVisible: true,
            })
        } else {
            let marker = {
                description: this.state.currentDescription,
                lat: this.state.currentLat,
                lng: this.state.currentLng
            };
            markers.push(marker);
            this.setState({
                markers: markers,
                currentLng: 0,
                currentLat: 0,
                currentDescription: "",
            });
            this.closeInputForLocationDescription();
            this.sendDataToNativeApp(markers);
        }
    };


    deleteMarker(marker) {
        let markers = this.state.markers;
        let index = markers.indexOf(marker);
        markers.splice(index, 1);
        this.setState({
            markers: markers
        })
        this.sendDataToNativeApp(markers);
    };

    render() {
        const customIcon = L.icon({
            iconRetinaUrl: iconRetina,
            iconUrl: iconMarker,
            shadowUrl: iconShadow,
            iconSize: [40, 40],
            iconAnchor: [7, 40]
        });

        return (
            <Container fluid className="mobileMapOuterContainer">

                {this.state.errMSGVisible &&
                    <ErrDiv errorMessage={this.state.errorText} closeErrDiv={this.closeErrDiv}/>}

                {this.state.inputForLocationDescription &&
                    <div className="markerInputDiv">
                        <form onSubmit={(e) => this.addMarker(e)}>
                            <label className="descriptionLabel">
                                Description:
                                <br/>
                                <input type="text" name="description" className="description"
                                       value={this.state.currentDescription}
                                       onChange={this.handleDescriptionChange}/>
                            </label>
                            <br/>
                            <input type="submit" value="Submit" className="submitLocationButton"/>
                            <button onClick={this.closeInputForLocationDescription}
                                    className="closeLocationInputButton">
                                Close
                            </button>
                        </form>
                    </div>
                }

                <Map style={{width: '100%', height: '100vh', zIndex: 1}}
                     center={this.state.centerMap} zoom={this.state.zoom} maxZoom={this.state.maxZoom}
                     bound={this.state.bound}
                     scrollWheelZoom={true} minZoom={this.state.minZoom} doubleClickZoom={false}
                     onClick={this.openInputForLocationDescription}>
                    <TileLayer className="attribution"
                               attribution='Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                               url="https://stamen-tiles-{s}.a.ssl.fastly.net/terrain/{z}/{x}/{y}{r}.png"
                    />
                    {this.state.markers.map((marker, index) => (
                        <Marker key={index} position={[marker.lat, marker.lng]} icon={customIcon}>
                            <Popup>
                                <span style={{color: "cyan"}}>{marker.description}</span>
                                <button onClick={() => this.deleteMarker(marker)}
                                        className="deleteLocationButton">
                                    Delete
                                </button>
                            </Popup>
                        </Marker>
                    ))}
                </Map>
            </Container>
        );
    }
}

export default geolocated({
    positionOptions: {
        enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
})(MobileMap);