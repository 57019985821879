import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";

function LineChart({ chartData, parameter }) {

  const [colors, setColors] = useState([]);
  const [hours, setHours] = useState([]);

  useEffect(() => {
    generateColors(chartData);
    generateHours(chartData);
  }, [chartData])

  const generateColorForPoint = (value) => {
    if(parameter === 'alert'){
      if(value < 1){
          return '#dcdcdc'
      } else if(value < 2){
          return 'green'
      } else if(value < 3){
          return 'orange'
      } else if(value < 4){
          return 'red'
      } else if(value < 5){
          return 'violet'
      } else {
          return 'gray'
      }
    } else if (parameter === 'rain'){
      if(value < 1){
        return 'violet'
    } else if(value < 2.5){
        return 'blue'
    } else if(value < 5){
        return 'cyan'
    } else if(value < 7.5){
        return 'turquoise'
    } else if(value < 10){
        return 'green'
    } else if(value < 12.5){
        return 'chartreuse'
    } else if(value < 15){
        return 'yellow' 
    } else if(value < 17.5){
        return 'brown'
    } else if(value < 20){
        return 'red'
    } else if(value >= 20){
        return 'orange'
    }
    }
  }

  const generateColors = (data) => {
    let colors = data.map((param) => generateColorForPoint(param.val))
    setColors(colors);
  }
  
  const generateHours = (data) => {
    let hours = data.map((param) => param.cas + 'ч')
    setHours(hours);
  }

  return (
    <div className="chart-container" style={{width: '100%', height: '95%'}}>
      <Line
         data={{
            labels: hours,
            datasets: [
              {
                data: chartData.map(param => param.val),
                hoverOffset: 4,
                pointBackgroundColor: colors,
                borderWidth: 1.2,
                borderColor: 'black',
                pointRadius: 6,
                yAxis: [{
                    position: 'left'
                }]
              }
            ],
        }}
          options={{
            maintainAspectRatio: false,
            title: false,
            layout: {
                padding: {
                  left: 4
                }
            },
            scales: {
              yAxes: [{
                  display: true,
                  stacked: true,
                  ticks: {
                      min: 0, // minimum value
                      max: 6 // maximum value
                  }
              }]
            }
          }}
          legend={{
            display: false
          }}
      />
    </div>
  );
}
export default LineChart;